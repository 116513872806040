import { CloudMember, CloudMemberRequest, ConnectToken, UpdateCloudMemberRequest } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const manufacturerApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getManufacturerList: builder.query<CloudMember[], void>({
            query: () => ({
                url: 'manufacturer/all',
                method: 'GET',

            }),
            providesTags: ['Manufacturer'],
        }),
        getAllManufactures: builder.mutation<CloudMember[], void>({
            query: () => ({
                url: 'manufacturer/all',
                method: 'GET',

            }),
        }),
        addManufacturer: builder.mutation<CloudMember, CloudMemberRequest>({
            query: (manufacturer) => ({
                url: 'manufacturer/signup',
                method: 'POST',
                body: manufacturer,
            }),
            invalidatesTags: ['Manufacturer', 'User']
        }),
        updateManufacturer: builder.mutation<CloudMember, UpdateCloudMemberRequest>({
            query: (manufacturer) => ({
                url: 'manufacturer/update',
                method: 'POST',
                body: manufacturer,
            }),
            invalidatesTags: ['Manufacturer']
        }),
        acceptRetailerToken: builder.mutation<ConnectToken, ConnectToken>({
            query: (token) => ({
                url: 'manufacturer/accept_retailer_token',
                method: 'POST',
                body: token,
            }),
            invalidatesTags: ['Manufacturer', 'Retailer', 'User']
        }),
        switchManufacturerActive: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `manufacturer/switch_active?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Manufacturer']
        }),
        deleteManufacturer: builder.mutation<string, string>({
            query: (id) => ({
                url: `manufacturer/remove?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Manufacturer']
        }),
        undeleteManufacturer: builder.mutation<string, string>({
            query: (id) => ({
                url: `manufacturer/undelete?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Manufacturer']
        }),
        getManufacturer: builder.query<CloudMember, string>({
            query: (id) => ({
                url: `manufacturer/get?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['Manufacturer'],
        }),
        fetchManufacturer: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `manufacturer/get?id=${id}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useFetchManufacturerMutation, useUndeleteManufacturerMutation, useGetAllManufacturesMutation, useAcceptRetailerTokenMutation, useSwitchManufacturerActiveMutation, useGetManufacturerListQuery, useGetManufacturerQuery, useDeleteManufacturerMutation, useUpdateManufacturerMutation, useAddManufacturerMutation } = manufacturerApi;
