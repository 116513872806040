import { Grid, styled } from '@mui/material';
import SingleColumn from '../PageTemplates/SingleColumn';
import PinnedPatientList from './fragments/PinnedPatientList';
import ButtonBar from '../User/fragments/ButtonBar';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import AssignedList from './fragments/AssignedList';
import UnAssignedList from './fragments/UnAssignedList';
import Avatar from '../Avatar/Avatar';
import theme, { largeFont } from '../../assets/theme';
import { useGetDeviceListQuery } from '../../api/deviceApi';
import { DeviceMessage } from '../../types/types';
import useFetchPatients from '../../hooks/useFetchPatients';
import useFetchUnassignedSessions from '../../hooks/useFetchUnassignedSessions';

const ColumnGrid = styled(Grid)({
    borderRadius: '10px',
    height: '100%',
    overflow: 'auto',
});

export type SelectedPatient = {
    id: string;
    patientId: string;
    name: string;
};

const ResultAssignment = () => {
    const { isUnassignedSessionsLoading, unassignedSessions } = useFetchUnassignedSessions();
    const { isPatientsLoading, patients } = useFetchPatients();
    const { isLoading, data } = useGetDeviceListQuery();
    const [selectedPatient, setSelectedPatient] = useState<SelectedPatient>();
    const [scrollPos, setScrollPos] = useState(0);
    const [devices, setDevices] = useState<DeviceMessage[]>([]);

    useEffect(() => {
        if (!isLoading && data) {
            setDevices(() => data);
        }
    }, [isLoading, data]);

    if (isLoading || isPatientsLoading || isUnassignedSessionsLoading) {
        return <></>;
    }

    const showDetails = (patient: SelectedPatient, scrollPos: number) => {
        setScrollPos(scrollPos);
        setSelectedPatient(() => patient);
    };

    const renderContent = () => {
        return (
            <Grid container direction="row" sx={{ height: '100%' }} spacing={0}>
                <ColumnGrid item xs={4} >
                    <div style={{ paddingLeft: '20px', paddingRight: '0px', marginRight: '10px', height: '100%' }}>
                        <div style={{
                            width: '100%',
                            paddingTop: '20px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            {t('result-assignment-list-title')}
                        </div>

                        <ButtonBar
                            showFilter={false}
                            searchFieldPlaceholder={t('patient-searchfield-placeholder')}
                            addEntryLabel={t('user-add-button')}
                            showAddButton={false}
                            addNewEntry={() => { }} />

                        {patients && <PinnedPatientList patients={patients} scrollPos={scrollPos} selectedPatient={selectedPatient} showDetails={showDetails} />}
                    </div>
                </ColumnGrid>
                <ColumnGrid item xs={4} >
                    <div style={{ paddingLeft: '10px', paddingRight: '0px', marginRight: '10px', height: '100%' }}>
                        <div style={{
                            width: '100%',
                            paddingTop: '14px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingRight: '10px', alignContent: 'center' }}>
                                    <Avatar name={selectedPatient?.name} />
                                </div>
                                <div style={{ alignContent: 'center' }}>{t('assigned-sessions-header-title', { name: selectedPatient?.name })}</div>
                            </div>
                        </div>
                        {selectedPatient && <AssignedList devices={devices} patientId={selectedPatient.patientId} />}
                    </div>
                </ColumnGrid >
                <ColumnGrid item xs={4}>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px', marginRight: '10px', height: '100%' }}>
                        <div style={{
                            width: '100%',
                            paddingTop: '20px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            {t('unassigned-sessions-header-title')}
                        </div>

                        {selectedPatient && <UnAssignedList unassignedSessions={unassignedSessions} devices={devices} patientId={selectedPatient.patientId} />}
                    </div>
                </ColumnGrid>
            </Grid >
        );
    };

    return (
        <SingleColumn component={renderContent()} />
    );

};

export default ResultAssignment;
