import { Box, Divider, InputAdornment, MenuItem, Select, Stack, styled, } from "@mui/material";
import { SetStateAction, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import Avatar from "../../Avatar/Avatar";
import { t } from "i18next";
import StyledTextField from "../../StyledTextField/StyledTextField";
import StyledMultilineField from "../../StyledTextField/StyledMultilineField";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import VisibilityOffIcon from "../../../icons/VisibilityOffIcon";
import VisibilityOnIcon from "../../../icons/VisibilityOnIcon";
import { UserRole } from "../../../store/authSlice";
import StyledText from "../../StyledTextField/StyledText";
import StyledLabel from "../../StyledTextField/StyledLabel";
import TheraButton from "../../TheraButtons/TheraButton";
import { largeFont } from "../../../assets/theme";
import { isEmpty, roleName } from "../../ResultAssignment/fragments/SessionDetailsBox";

const LoginTextField = styled(StyledTextField)(({ theme }) => ({
    'input::-ms-reveal ,input::-ms-clear': {
        display: 'none'
    }
}));

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    addMember: any;
    role: UserRole;
};

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

const AddUser = ({ role, addMember, setPage }: DetailsProps) => {
    const username = useRef<HTMLInputElement>();
    const email = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();
    const givenName = useRef<HTMLInputElement>();
    const sureName = useRef<HTMLInputElement>();
    const password = useRef<HTMLInputElement>();
    const [showPassword, setShowPassword] = useState(false);
    const [userRole, setUserRole] = useState(role.toString());

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        if (sureName.current && isEmpty(sureName.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-sure-name-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        if (givenName.current && isEmpty(givenName.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-given-name-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        if (username.current && isEmpty(username.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-username-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        if (password.current && isEmpty(password.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-password-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        const params = {
            role: userRole,
            username: username.current?.value ?? '',
            email: email.current?.value ?? '',
            comment: comment.current?.value ?? '',
            sureName: sureName.current?.value ?? '',
            givenName: givenName.current?.value ?? '',
            password: password.current?.value ?? ''
        };

        addMember(params);
        setPage(Pages.DETAILS);

    };

    const renderRole = () => {
        if (role === UserRole.ClinicAdmin) {
            return (<Select sx={{ height: '50px', fontSize: largeFont }} id="select-clinic-user-role" value={userRole} onChange={(e) => { setUserRole(e.target.value); }}>
                <MenuItem sx={{ fontSize: largeFont }} value={UserRole.ClinicAdmin}>{roleName(UserRole.ClinicAdmin)}</MenuItem>
                <MenuItem sx={{ fontSize: largeFont }} value={UserRole.ClinicUser}>{roleName(UserRole.ClinicUser)}</MenuItem>
            </Select >);
        }
        return <StyledText>{roleName(role)}</StyledText>;
    };

    return (
        <form onSubmit={handleSubmit} style={{ padding: '20px', height: '100%' }}>
            <Box sx={{ height: '100%' }} >
                <Stack direction="column" sx={{ paddingRight: '15px', height: '100%', overflow: 'auto' }} >

                    <Stack sx={{ display: 'flex', paddingBottom: '20px', width: '100%', }} alignItems="center" direction="row">
                        <div style={{ marginRight: '20px' }}><Avatar size="large" name={''} /></div>
                        <StyledLabel sx={{ fontWeight: 'normal' }}>{t('add-user-button-label')}</StyledLabel>
                    </Stack >

                    <Divider />

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                        <StyledMultilineField
                            variant="outlined"
                            color="primary"
                            multiline
                            rows={3}
                            inputRef={comment}
                        />
                    </Stack>

                    <Divider />

                    <Stack pt="20px" direction="row" spacing={2}>
                        <StyledLabel sx={{ width: '50%' }}>{t('cloud-member-form-sure-name-label')}*</StyledLabel>
                        <StyledLabel>{t('cloud-member-form-given-name-label')}*</StyledLabel>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={sureName}
                        />
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={givenName}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-email-label')}</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={email}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-username-label')}*</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={username}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-password-label')}*</StyledLabel>
                        <LoginTextField
                            variant="outlined"
                            color="primary"
                            type={showPassword ? "text" : "password"}
                            inputRef={password}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end"> {
                                            showPassword ?
                                                (<VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                                :
                                                (<VisibilityOnIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                        }
                                        </InputAdornment>
                                    ),
                                }
                            }}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-role-label')}</StyledLabel>
                        {renderRole()}
                    </Stack>

                    <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
                        <Stack spacing={'20px'} direction="row">
                            <TheraButton sx={{ width: '50%' }} onClick={() => setPage(Pages.DETAILS)} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                            <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </form >
    );

};

export default AddUser;
