import { useEffect, useRef } from "react";
import { Grid2 as Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ListUserResponse, UserMessage } from "../../../types/types";
import Avatar from "../../Avatar/Avatar";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, mediumFont, smallFont } from "../../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectActiveFilter, selectDeletedFilter, selectInactiveFilter } from "../../../store/filterState";
import { selectListField, selectListFilter, selectListOrder, setListOrderState } from "../../../store/listStateSlice";
import { selectIsSystemaAdmin } from "../../../store/authSlice";
import TrashIcon from "../../../icons/TrashIcon";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";
import { getActiveStatus } from "../../CloudMember/MemberListPanels/CloudMemberList";
import { roleName } from "../../ResultAssignment/fragments/SessionDetailsBox";

const getOrganisation = (data: UserMessage) => {
    if (data.organisation) {
        return data.organisation;
    }
    return '';
};

const getActive = (data: UserMessage) => {
    if (data.active) {
        return '1';
    }
    return '0';
};

const getRoleOrder = (order: number, a: UserMessage, b: UserMessage) => {
    if (roleName(a.role) > roleName(b.role)) {
        return order;
    }
    if (roleName(a.role) < roleName(b.role)) {
        return -order;
    }
    return 0;
};

const sortData = (sortField: string, sortOrder: string, data: UserMessage[]) => {
    if (!data) {
        return [];
    }
    return data.slice().sort((a, b) => {
        const order = sortOrder === "up" ? 1 : -1;
        switch (sortField) {
            case 'role':
                return getRoleOrder(order, a, b);
            case 'organisation':
                if (getOrganisation(a) > getOrganisation(b)) {
                    return order;
                }
                if (getOrganisation(a) < getOrganisation(b)) {
                    return -order;
                }
                break;
            case 'active':
                if (getActive(a) > getActive(b)) {
                    return order;
                }
                if (getActive(a) < getActive(b)) {
                    return -order;
                }
                break;
            default:
                if (a.username > b.username) {
                    return order;
                }
                if (a.username < b.username) {
                    return -order;
                }
        }

        return 0;
    });
};


type ListProps = {
    showDetails: (id: string, scrollPos: number) => void;
    selectedId?: string;
    data?: ListUserResponse;
    scrollPos: number;
};

const UserList = ({ scrollPos, data, selectedId, showDetails }: ListProps) => {
    const dispatch = useAppDispatch();
    const sortOrder = useAppSelector(selectListOrder);
    const sortField = useAppSelector(selectListField);
    const ref = useRef<HTMLTableElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const active = useAppSelector(selectActiveFilter);
    const inactive = useAppSelector(selectInactiveFilter);
    const deleted = useAppSelector(selectDeletedFilter);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const filter = useAppSelector(selectListFilter);

    useEffect(() => {
        if (ref.current?.parentElement) {
            ref.current.parentElement.scrollTo({ top: scrollPos });
        }
    }, [ref, scrollPos]);


    const clickInRow = ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        if (selectedId !== id) {
            if (tableRef.current) {
                showDetails(id, tableRef.current?.scrollTop);
            } else {
                showDetails(id, 0);
            }
        }
    });

    const getDeletedColumn = (member: UserMessage) => {
        if (isSystemAdmin) {
            if (member.deleted) {
                return <DataTableCell sx={{ width: '20px' }}><TrashIcon sx={{ fontSize: mediumFont }} /></DataTableCell>;

            }
            return <DataTableCell sx={{ width: '20px' }}></DataTableCell>;
        }
    };


    const isInFilter = (member: UserMessage) => {
        if (member.username.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || (member.organisation && member.organisation.toLowerCase().indexOf(filter.toLowerCase()) >= 0)) {
            return true;
        }
        return false;
    };
    const getFilteredList = (list?: UserMessage[]) => {
        if (list) {
            const filteredList = list.filter((entry) => isInFilter(entry))
                .filter((entry) => (!active && !inactive) || (active && entry.active) || (inactive && !entry.active))
                .filter((entry) => !deleted || (deleted && entry.deleted));
            const mappedList = filteredList.map((member: UserMessage) => {
                return (
                    <StyledTableRow
                        selected={selectedId === member.id}
                        key={member.id} sx={{ height: '80px', cursor: 'pointer' }} hover onClick={(event) => clickInRow(event, member.id)}
                    >
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                        <DataTableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar name={`${member.givenName} ${member.sureName}`.trim()} size="small" />
                                <Typography fontSize={largeFont} pl={'10px'}>{member.username}</Typography>
                            </div>
                        </DataTableCell>
                        <DataTableCell sx={{ width: '220px' }}>
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {roleName(member.role)}
                            </Typography>
                        </DataTableCell>
                        {isSystemAdmin && <DataTableCell sx={{ width: '220px' }}>
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {member.organisation}
                            </Typography>
                        </DataTableCell>}
                        <DataTableCell sx={{ alignItems: 'center', height: '80px', justifyContent: 'center', display: 'flex', userSelect: 'none' }}>{getActiveStatus(member)}</DataTableCell>
                        {getDeletedColumn(member)}
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                    </StyledTableRow>
                );
            });
            return mappedList;
        }
    };

    const renderList = () => {
        let list = data;
        if (data && sortField) {
            switch (sortOrder) {
                case "up":
                    list = sortData(sortField, "up", data);
                    break;
                case "down":
                    list = sortData(sortField, "down", data);
                    break;
            }
        }
        const result = getFilteredList(list);
        return result;
    };

    const getOpacity = (field: string, order: string) => {
        if (field === sortField && sortOrder === order) {
            return 1;
        }
        return 0.3;
    };

    const toggleColumnSearch = (name: string) => {
        const newOrder = sortOrder === "up" ? "down" : "up";
        dispatch(setListOrderState({ order: newOrder, field: name }));
    };

    const getSortedColumnHeader = (label: string, column: string) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: smallFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: smallFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    return (
        <TableContainer ref={tableRef} sx={{ height: 'calc(100% - 95px)', paddingLeft: '0px', paddingRight: '0px' }}>
            <Table ref={ref} stickyHeader>
                <TableHead >
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ width: (isSystemAdmin ? '30%' : '40%') }}>
                            {getSortedColumnHeader(t('cloud-member-list-name-header'), "username")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: (isSystemAdmin ? '30%' : '40%') }}>
                            {getSortedColumnHeader(t('cloud-member-list-role-header'), "role")}
                        </HeaderTableCell>
                        {isSystemAdmin && <HeaderTableCell sx={{ width: '25%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-organisation-header'), "organisation")}
                        </HeaderTableCell>}
                        <HeaderTableCell sx={{ minWidth: '100px' }}>
                            {getSortedColumnHeader(t('cloud-member-list-status-header'), "active")}
                        </HeaderTableCell>
                        {isSystemAdmin && <HeaderTableCell sx={{ width: '20px' }}></HeaderTableCell>}
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table >
        </TableContainer >
    );
};
export default UserList;
