import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from "@mui/material";
import StyledSearchField from '../../StyledTextField/StyledSearchField';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { isEmpty } from '../../ResultAssignment/fragments/SessionDetailsBox';



type FilterProps = {
    onChange: (filter: string) => void;
    value: string;
    placeholder: string;
};

const SearchField = ({ placeholder, value, onChange }: FilterProps) => {
    const [searchString, setSearchString] = useState<string>(value);


    const clearField = () => {
        setSearchString('');
        onChange('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
        onChange(e.target.value);
    };

    return (
        <StyledSearchField
            autoFocus={false}
            value={searchString}
            placeholder={placeholder}
            variant="outlined"
            type="text"
            onChange={handleChange}
            InputProps={
                {
                    endAdornment: (
                        <InputAdornment position="end"> {
                            !isEmpty(value) ?
                                <Box sx={{ display: 'flex', verticalAlign: 'center', cursor: 'pointer' }} onClick={clearField}><CloseIcon /></Box> : <SearchIcon />
                        }
                        </InputAdornment>
                    ),
                }
            }
        />

    );
};

export default SearchField;
