import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";
import StyledTextField from '../../StyledTextField/StyledTextField';

type FilterProps = {
    onChange: (filter: string) => void;
};
const SearchField = ({ onChange }: FilterProps) => {
    return (
        <StyledTextField
            sx={{ paddingRight: '20px' }}
            variant="outlined"
            type="text"
            onChange={(e) => onChange(e.target.value)}
            InputProps={
                {
                    endAdornment: (
                        <InputAdornment position="end"> {
                            <SearchIcon />
                        }
                        </InputAdornment>
                    ),
                }
            }
        />

    );
};

export default SearchField;
