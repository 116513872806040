import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { PopoverEntry } from "../../../CloudMember/DetailsPanel/DetailsHeader";
import Avatar from "../../../Avatar/Avatar";
import { useDeletePatientMutation, useGetPatientQuery, useSwitchPatientActiveMutation } from "../../../../api/patientApi";
import { getFullNameOrCode } from "../../../ResultAssignment/fragments/PinnedPatientList";
import ThreeDottMenuIcon from "../../../../icons/ThreeDottMenuIcon";
import { useRef, useState } from "react";
import { largeFont, smallFont } from "../../../../assets/theme";
import EditIcon from "../../../../icons/EditIcon";
import ShowPatientDetails from "./ShowPatientDetails";
import EditPatientDetails from "./EditPatientDetails";
import TrashIcon from "../../../../icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import DeactivateIcon from "../../../../icons/DeactivateIcon";
import ActivateIcon from "../../../../icons/ActivateIcon";
import ConfirmDialog from "../../../CloudMember/ConfirmDialog/ConfirmDialog";


type Props = {
    patientId: string;
};
const PatientDetails = ({ patientId }: Props) => {
    const dialogRef = useRef();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const { data, isLoading } = useGetPatientQuery(patientId);
    const [editMode, setEditMode] = useState(false);
    const [deletePatientId, setDeletePatientId] = useState<string | undefined>();

    const [deletePatient] = useDeletePatientMutation();
    const [switchActive] = useSwitchPatientActiveMutation();
    const navigate = useNavigate();

    if (isLoading || !data) {
        return <div>loading...</div>;
    }

    const editPatient = () => {
        setAnchorEl(undefined);
        setEditMode(true);
    };

    const removePatient = () => {
        deletePatient(data.id).then(() => {
            navigate('/patients');
        });
    };

    const switchPatientActive = () => {
        switchActive({ patientId: data.id, active: !data.active }).then((result) => {
            if ('data' in result) {
                setAnchorEl(undefined);
            }
        });
    };

    const renderDropDown = () => {
        return (
            <Popover
                open={open}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    borderRadius: '10px',
                    userSelect: 'none'
                }}
            >
                <Stack direction="column" spacing={2} padding="10px">
                    <PopoverEntry onClick={() => { editPatient(); }}><EditIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-edit')}</PopoverEntry>
                    {data.active && <PopoverEntry onClick={() => switchPatientActive()}><DeactivateIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('deactivate-label')}</PopoverEntry>}
                    {!data.active && <PopoverEntry onClick={() => switchPatientActive()}><ActivateIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('activate-label')}</PopoverEntry>}
                    <PopoverEntry onClick={() => { setAnchorEl(undefined); setDeletePatientId(data.id); }}><TrashIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-delete')}</PopoverEntry>
                </Stack>
            </Popover >
        );

    };

    return <Box sx={{ height: '100%', padding: '10px' }} >
        <Stack direction="column" sx={{ height: '100%', overflow: 'auto', padding: '10px' }} >
            <Stack direction="row" spacing={2} pb="20px">
                <Avatar size="large" name={getFullNameOrCode(data)} />
                <Stack direction="row" style={{ width: 'calc(100% - 90px)' }}>
                    <Stack direction="column"  >
                        <Typography sx={{ fontSize: largeFont }} fontWeight="bold" pt="10px">{getFullNameOrCode(data)}</Typography>
                        <Typography sx={{ fontSize: largeFont }}>Patient {data.patientCode}</Typography>
                    </Stack>
                </Stack>
                <Box ref={dialogRef} onClick={() => setAnchorEl(dialogRef.current)} style={{ cursor: 'pointer' }}>
                    <ThreeDottMenuIcon /></Box>
            </Stack>

            <Divider />
            {editMode ? <EditPatientDetails onClose={() => { setEditMode(false); }} data={data} /> : <ShowPatientDetails data={data} />}
        </Stack>
        {!editMode && renderDropDown()}
        {deletePatientId && <ConfirmDialog title={t('delete-dialog-title')} isVisible={true} message={t('delete-patient-dialog-message')} okCallback={removePatient} cancelCallback={() => { setDeletePatientId(undefined); }} />}

    </Box >;


};

export default PatientDetails;;
