import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API_BASE_URL } from "../config/constanst";
import { RootState } from "../store";
import { tokenExpiresIn } from "../store/authSlice";
import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "../components/CloudMember/DetailsPanel/AddCloudMember";

export const baseQuery = fetchBaseQuery(
    {
        baseUrl: `${API_BASE_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const tokenType = (getState() as RootState).auth.tokenType;
            const token = (getState() as RootState).auth.accessToken;
            const isAuthorized = tokenExpiresIn(token) > 0;
            if (isAuthorized) {
                headers.set('Authorization', `${tokenType} ${token}`);
            }
            return headers;
        },
        responseHandler(response) {
            if (response.status !== 200) {
                console.log('### response status %s', response.status);
            }
            if (response.status === 400) {
                response.text().then(text => {
                    enqueueSnackbar(ErrorMessage(text), { key: 'response-error', preventDuplicate: true, anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
                });
            }
            return response.json();
        },

    }

);
