import { SetStateAction } from "react";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { useAppSelector } from "../../../store";
import { selectIsClinicAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import CloudMemberDetails from "../../CloudMember/DetailsPanel/CloudMemberDetails";
import { t } from 'i18next';
import { Trans } from "react-i18next";
import { useDeleteClinicMutation, useGetClinicQuery, useSwitchClinicActiveMutation, useUndeleteClinicMutation } from "../../../api/clinicApi";

type DetailsProps = {
    clinicId: string;
    setPage: (value: SetStateAction<Pages>) => void;
};

const ClinicDetails = ({ setPage, clinicId }: DetailsProps) => {
    const { data, isLoading } = useGetClinicQuery(clinicId, { refetchOnMountOrArgChange: true });
    const [switchActive] = useSwitchClinicActiveMutation();
    const [undelete] = useUndeleteClinicMutation();
    const [deleteRetailer] = useDeleteClinicMutation();

    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);

    if (isLoading) {
        return <div>loading...</div>;
    }
    const switchActiveFlag = () => {
        switchActive(clinicId);
    };

    const deleteEntry = () => {
        if (data?.deleted) {
            undelete(clinicId);
            return;
        }
        deleteRetailer(clinicId);
    };

    return (
        <CloudMemberDetails
            memberType={t('member-type-clinic')}
            deleteEntryMessage={<Trans i18nKey={'delete-clinic-message'} values={{ name: data?.name }} />}
            undeleteEntryMessage={<Trans i18nKey={'undelete-clinic-message'} values={{ name: data?.name }} />}
            data={data}
            switchActive={isSystemAdmin || isRetailerAdmin ? switchActiveFlag : undefined}
            editEntry={isSystemAdmin || isClinicAdmin ? () => setPage(Pages.EDIT) : undefined}
            deleteEntry={(isSystemAdmin) ? deleteEntry : undefined}
        />
    );
};

export default ClinicDetails;
