import { SetStateAction } from "react";
import { Pages } from "../../../CloudMember/MemberListPanels/listPanelGlobals";
import { Box, Divider, Grid, Stack } from "@mui/material";
import DetailsHeader from "../../../CloudMember/DetailsPanel/DetailsHeader";
import { t } from 'i18next';
import { useAppSelector } from "../../../../store";
import { selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin, selectIsClinicAdmin } from "../../../../store/authSlice";
import { Trans } from "react-i18next";
import { useDeleteDeviceMutation, useSwitchDeviceActiveMutation, useUndeleteDeviceMutation } from "../../../../api/deviceApi";
import DeviceAvatar from "../../../Avatar/DeviceAvatar";
import StyledLabel from "../../../StyledTextField/StyledLabel";
import StyledText from "../../../StyledTextField/StyledText";
import FooterText from "../../../StyledTextField/FooterText";
import { DeviceMessage } from "../../../../types/types";
import { dateNumbersString, dateString } from "../../../ResultAssignment/fragments/SessionDetailsBox";

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    data: DeviceMessage;
};

const LyraDeviceDetails = ({ setPage, data }: DetailsProps) => {
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const [switchActive] = useSwitchDeviceActiveMutation();
    const [undelete] = useUndeleteDeviceMutation();
    const [deleteDevivce] = useDeleteDeviceMutation();

    const switchActiveFlag = () => {
        switchActive(data.id);
    };

    const deleteEntry = () => {
        if (data?.deleted) {
            undelete(data.id);
            return;
        }
        deleteDevivce(data.id);
    };


    const getManufacturerName = () => {
        if (isSystemAdmin || isManufacturerAdmin || isRetailerAdmin) {
            return data.manufacturerName ?? '-';
        }
        return '-';
    };

    const getRetailerName = () => {
        return data.retailerName ?? '-';
    };

    const getClinicName = () => {
        if (isSystemAdmin || isRetailerAdmin || isClinicAdmin) {
            return data.clinicName ?? '-';
        }
        return '-';
    };

    return (
        <Box sx={{ height: '100%', paddingLeft: '20px', paddingRight: '10px', paddingBottom: '10px' }} >
            <Stack direction="column" sx={{ height: '100%', paddingRight: '10px', overflow: 'auto' }} >
                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="row">
                    <div style={{ marginRight: '20px' }}>
                        <DeviceAvatar size="large" typ={data?.type} />
                    </div>
                    <DetailsHeader
                        memberType={data?.therapyMethod[0] ?? ''}
                        isActive={Boolean(data?.active)}
                        isDeleted={Boolean(data?.deleted)}
                        name={data?.name ?? ''}
                        editEntry={() => setPage(Pages.EDIT)}
                        switchActive={isSystemAdmin || isManufacturerAdmin ? switchActiveFlag : undefined}
                        deleteEntry={(isSystemAdmin) ? deleteEntry : undefined}
                        deleteEntryMessage={<Trans i18nKey={'delete-device-message'} values={{ name: data?.name }} />}
                        undeleteEntryMessage={<Trans i18nKey={'undelete-device-message'} values={{ name: data?.name }} />}
                    />
                </Stack >

                <Divider />

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledText>{data?.comment}</StyledText>
                </Stack>

                <Divider />

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-serial-label')}</StyledLabel>
                    <StyledText>{data?.serial}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-manufacturer-label')}</StyledLabel>
                    <StyledText>{getManufacturerName()}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-retailer-label')}</StyledLabel>
                    <StyledText>{getRetailerName()}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-clinic-label')}</StyledLabel>
                    <StyledText>{getClinicName()}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-token-label')}</StyledLabel>
                    <StyledText>{data?.token}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-debug-label')}</StyledLabel>
                    <StyledText>{data?.debugMode ? 'ein' : 'aus'}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-licence-label')}</StyledLabel>
                    <StyledText>{data.licenceDate ? dateNumbersString(data?.licenceDate) : ''}</StyledText>
                </Stack>

                <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
                    <Divider sx={{ marginBottom: '15px' }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                        </Grid>
                        <Grid item xs={6}>
                            <FooterText>{dateString(data?.lastUpdated ?? data?.created)}</FooterText>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <FooterText>{dateString(data?.created)}</FooterText>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Box>
    );
};

export default LyraDeviceDetails;
