import { t } from "i18next";
import { largeFont } from "../../../../assets/theme";
import { DeviceMessage, MappedTherapySessionData } from "../../../../types/types";
import SessionsFilterButton from "./SessionsFilterButton";

type Props = {
    assignedSessions: MappedTherapySessionData[];
    devices: DeviceMessage[];
};

const SessionsListButtonBar = ({ devices, assignedSessions }: Props) => {
    return (
        <div style={{ padding: '20px', display: 'flex', width: '100%' }}>
            <div style={{ fontWeight: 'bold', fontSize: largeFont, alignItems: 'center', display: 'flex', width: '100%' }}>{t('last-sessions-header-title')}</div>
            < div style={{ display: 'flex', alignSelf: 'end' }}>
                <SessionsFilterButton devices={devices} assignedSessions={assignedSessions} />
            </div>
        </div >
    );
};
export default SessionsListButtonBar;
