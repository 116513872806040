import { Box, Divider, Grid2 as Grid, InputAdornment, Stack, Typography, styled } from "@mui/material";
import { SetStateAction, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import Avatar from "../../Avatar/Avatar";
import VisibilityOffIcon from "../../../icons/VisibilityOffIcon";
import VisibilityOnIcon from "../../../icons/VisibilityOnIcon";
import { Pages } from "../MemberListPanels/listPanelGlobals";
import { t } from "i18next";
import StyledTextField from "../../StyledTextField/StyledTextField";
import StyledMultilineField from "../../StyledTextField/StyledMultilineField";
import TheraButton from "../../TheraButtons/TheraButton";
import { largeFont } from "../../../assets/theme";
import { isEmpty } from "../../ResultAssignment/fragments/SessionDetailsBox";
import { JSX } from "react/jsx-runtime";

const LoginTextField = styled(StyledTextField)(({ theme }) => ({
    'input::-ms-reveal ,input::-ms-clear': {
        display: 'none'
    }
}));

const StyledLabel = styled(Typography)({
    fontSize: largeFont,
    fontWeight: 'bold',
});

export const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    addMember: any;
};

const AddCloudMember = ({ addMember, setPage }: DetailsProps) => {
    const name = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();
    const email = useRef<HTMLInputElement>();
    const zip = useRef<HTMLInputElement>();
    const city = useRef<HTMLInputElement>();
    const street = useRef<HTMLInputElement>();
    const streetNr = useRef<HTMLInputElement>();
    const phone = useRef<HTMLInputElement>();
    const contact = useRef<HTMLInputElement>();
    const adminSureName = useRef<HTMLInputElement>();
    const adminGivenName = useRef<HTMLInputElement>();
    const adminUsername = useRef<HTMLInputElement>();
    const adminPassowrd = useRef<HTMLInputElement>();
    const [showPassword, setShowPassword] = useState(false);

    const enqueueErrorMessage = (message: JSX.Element, field?: HTMLInputElement) => {
        if (field && isEmpty(field.value)) {
            enqueueSnackbar(message, { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return true;
        };
        return false;
    };

    const checkAndNotifyErrors = () => {
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-name-error')), name.current)) {
            return true;
        }
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-email-error')), email.current)) {
            return true;
        }
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-admin-sure-name-error')), adminSureName.current)) {
            return true;
        };
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-admin-given-name-error')), adminGivenName.current)) {
            return true;
        }
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-admin-username-error')), adminUsername.current)) {
            return true;
        };
        if (enqueueErrorMessage(ErrorMessage(t('cloud-member-form-no-admin-password-error')), adminPassowrd.current)) {
            return true;
        }
        return false;
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (checkAndNotifyErrors()) {
            return;
        }

        const params = {
            name: name.current?.value ?? '',
            comment: comment.current?.value ?? '',
            email: email.current?.value ?? '',
            zip: zip.current?.value ?? '',
            city: city.current?.value ?? '',
            street: street.current?.value ?? '',
            streetNr: streetNr.current?.value ?? '',
            phone: phone.current?.value ?? '',
            contact: contact.current?.value ?? '',
            adminSureName: adminSureName.current?.value ?? '',
            adminGivenName: adminGivenName.current?.value ?? '',
            adminUsername: adminUsername.current?.value ?? '',
            adminPassowrd: adminPassowrd.current?.value ?? ''
        };

        addMember(params).then((result: { error: { data: any; }; }) => {
            if ('error' in result) {
                return;
            } else {
                setPage(Pages.DETAILS);
            }
        });
    };

    return (
        <Box sx={{ height: '100%', padding: '20px' }} >
            <Stack direction="column" sx={{ paddingRight: '15px', height: '100%', overflow: 'auto' }} >
                <form onSubmit={handleSubmit} data-testid="member-form">
                    <Stack direction="row" spacing={0}>
                        <Avatar size="large" name={name.current?.value} />
                        <Stack direction="column" spacing={0} sx={{
                            width: 'calc(100% - 83px)',
                        }}>
                            <StyledLabel pl={'20px'}>{t('cloud-member-form-name-label')}*</StyledLabel>
                            <StyledTextField
                                sx={{ paddingLeft: '20px' }}
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={name}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "name"
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Divider sx={{ marginTop: '20px' }} />
                    <Stack pt="10px" direction="column" spacing={0}>
                        <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                        <StyledMultilineField
                            variant="outlined"
                            color="primary"
                            multiline
                            rows={3}
                            inputRef={comment}

                        />
                    </Stack>
                    <Divider sx={{ marginTop: '20px' }} />

                    <Grid container pt={'10px'}>
                        <Grid size={4}>
                            <StyledLabel>{t('cloud-member-form-zip-label')}</StyledLabel>
                        </Grid>
                        <Grid size={8}>
                            <StyledLabel>{t('cloud-member-form-city-label')}</StyledLabel>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid size={4}>
                            <StyledTextField
                                sx={{ paddingRight: '20px' }}
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={zip}
                            />
                        </Grid>
                        <Grid size={8}>
                            <StyledTextField
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={city}
                            />
                        </Grid>
                    </Grid>


                    <Grid container pt={'10px'}>
                        <Grid size={9}>
                            <StyledLabel>{t('cloud-member-form-street-label')}</StyledLabel>
                        </Grid>
                        <Grid size={3}>
                            <StyledLabel>{t('cloud-member-form-street-nr-label')}</StyledLabel>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid size={9}>
                            <StyledTextField
                                sx={{ paddingRight: '20px' }}
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={street}
                            />
                        </Grid>
                        <Grid size={3}>
                            <StyledTextField
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={streetNr}
                            />
                        </Grid>
                    </Grid>


                    <Stack pt="10px" direction="column" spacing={0}>
                        <StyledLabel>{t('cloud-member-form-contact-label')}</StyledLabel>
                        <StyledTextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={contact}

                        />
                    </Stack>
                    <Stack pt="10px" direction="column" spacing={0}>
                        <StyledLabel>{t('cloud-member-form-phone-label')}</StyledLabel>
                        <StyledTextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={phone}

                        />
                    </Stack>
                    <Stack pt="10px" direction="column" spacing={0}>
                        <StyledLabel>{t('cloud-member-form-email-label')}*</StyledLabel>
                        <StyledTextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={email}
                            slotProps={{
                                htmlInput: {
                                    "data-testid": "email"
                                }
                            }}

                        />
                    </Stack>

                    <Divider sx={{ marginTop: '20px' }} />

                    <Stack pt="20px" direction="column" spacing={0}>
                        <Typography fontWeight="bold" fontSize={largeFont}>{t('cloud-member-form-admin-section-label')}</Typography>
                    </Stack>

                    <Divider sx={{ marginTop: '20px' }} />

                    <Stack pt="10px" direction="column" spacing={0}>
                        <Stack direction="row">
                            <StyledLabel sx={{
                                width: '50%'
                            }}
                            >{t('cloud-member-form-sure-name-label')}*</StyledLabel>
                            <StyledLabel sx={{
                                paddingLeft: '20px',
                                width: '50%'
                            }}>{t('cloud-member-form-given-name-label')}*</StyledLabel>
                        </Stack>
                        <Stack direction="row">
                            <StyledTextField
                                sx={{
                                    width: '50%'
                                }}
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={adminSureName}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "adminSureName"
                                    }
                                }}

                            />
                            <StyledTextField
                                sx={{
                                    paddingLeft: '20px',
                                    width: '50%'
                                }}
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={adminGivenName}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "adminGivenName"
                                    }
                                }}

                            />
                        </Stack>
                        <Stack pt="10px" direction="column" spacing={0}>
                            <StyledLabel>{t('cloud-member-form-username-label')}*</StyledLabel>
                            <StyledTextField
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={adminUsername}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "adminUsername"
                                    }
                                }}

                            />
                        </Stack>
                        <Stack pt="10px" direction="column" spacing={0}>
                            <StyledLabel>{t('cloud-member-form-password-label')}*</StyledLabel>
                            <LoginTextField
                                variant="outlined"
                                color="primary"
                                type={showPassword ? "text" : "password"}
                                inputRef={adminPassowrd}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "adminPassword"
                                    },
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                showPassword ?
                                                    (<VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                                    :
                                                    (<VisibilityOnIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />)
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Stack pt="20px" direction="column" spacing={0}>
                        <Stack spacing={'20px'} direction="row">
                            <TheraButton sx={{ width: '50%' }} onClick={() => setPage(Pages.DETAILS)} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                            <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack >
        </Box >
    );
};

export default AddCloudMember;
