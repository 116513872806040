import { ButtonProps, styled } from "@mui/material";

type Props = {
    active?: boolean;
    position?: 'left' | 'center' | 'right';
    onClick: (e: any) => void;
};

const AvatarDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'position',
})<{
    active: boolean;
    position: string;
}>(({ theme, active, position }) => ({
    borderTopLeftRadius: position === 'left' ? '5px' : 0,
    borderBottomLeftRadius: position === 'left' ? '5px' : 0,
    borderTopRightRadius: position === 'right' ? '5px' : 0,
    borderBottomRightRadius: position === 'right' ? '5px' : 0,
    display: 'flex',
    fontWeight: active ? 'bold' : 'normal',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '100%',
    cursor: 'pointer',
    border: `solid 1px ${active ? theme.palette.darkgreen.main : theme.palette.primary.main}`,
    backgroundColor: active ? theme.palette.lightgreen.main : theme.palette.background.default,
    color: active ? theme.palette.darkgreen.main : theme.palette.primary.main
}));

const ClinicButton = ({ position, onClick, active = false, children }: Props & ButtonProps) => {
    return <AvatarDiv active={active} position={position ?? 'center'} onClick={onClick}>{children}</AvatarDiv>;
};

export default ClinicButton;
