import { ChartsXAxis, ChartsYAxis, LinePlot, MarkPlot, ResponsiveChartContainer } from "@mui/x-charts";
import theme from "../../../../assets/theme";
import { Box, Stack } from "@mui/material";
import ClinicButton from "../../../TheraButtons/ClinicButton";
import { useEffect, useLayoutEffect, useState } from "react";
import { MappedTherapySessionData } from "../../../../types/types";
import { format } from "date-fns";
import { exerciseDurationMinutes, getFnsLocale, iso8601ToDate } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import { useGetDeviceMappingsQuery } from "../../../../api/therapySessionsApi";

type Props = {
    assignedSessions: MappedTherapySessionData[];
    menuHeight?: number;
};

const SessionsLineChart = ({ menuHeight = 400, assignedSessions }: Props) => {
    const [activeState, setActiveState] = useState(0);
    const [sessions, setSessions] = useState<MappedTherapySessionData[]>();
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const { data, isLoading } = useGetDeviceMappingsQuery();

    useLayoutEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    });

    useEffect(() => {
        setSessions(() =>
            assignedSessions.filter(session => assignedSessions[0].serial === session.serial)
                .sort((a, b) => {
                    if (a.exerciseStartDate > b.exerciseStartDate) {
                        return -1;
                    }
                    if (a.exerciseStartDate < b.exerciseStartDate) {
                        return 1;
                    }
                    return 0;
                })
        );
    }, [assignedSessions, setSessions]);

    if (isLoading || !sessions) {
        return <></>;
    }

    const getSeries = () => {
        if (sessions) {
            switch (activeState) {
                case 0:
                    return sessions.map((session) => exerciseDurationMinutes(session));
                case 1:
                    return sessions.map((session) => parseFloat(session.chartResult1 ?? '0'));
                case 2:
                    return sessions.map((session) => parseFloat(session.chartResult2 ?? '0'));
                case 3:
                    return sessions.map((session) => parseFloat(session.chartResult3 ?? '0'));
            }
        }
        return [];
    };

    const getyAxisLabel = (value: any) => {
        switch (activeState) {
            case 0:
                return `${value} min`;
            case 1:
                return `${value} ${getUnit(1)}`;
            case 2:
                return `${value} ${getUnit(2)}`;
            case 3:
                return `${value} ${getUnit(3)}`;
        }
        return '';
    };

    const valueFormatter = (date: Date) => format(date, 'dd.MM.', { locale: getFnsLocale() });

    const getMax = () => {
        return sessions && sessions.length > 0 ? iso8601ToDate(sessions[0].exerciseStartDate) : 0;
    };

    const getTitle = () => {
        return `${sessions[0].type} - ${sessions[0].therapyMethod}`;
    };

    const getUnit = (idx: number) => {
        if (!data) {
            return '-';
        }
        return data.find((mapping) => mapping.mappingFieldName === `chartResult${idx}`)?.unit;
    };

    const getButtonLabel = (idx: number) => {
        if (!data) {
            return '-';
        }
        return data.find((mapping) => mapping.mappingFieldName === `chartResult${idx}`)?.label;
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box style={{ display: 'block', height: '100%' }}>
                <div style={{ padding: '20px', height: '30px', fontWeight: 'bold' }}>{getTitle()}</div>
                <ResponsiveChartContainer
                    xAxis={[
                        {
                            scaleType: 'time',
                            id: 'x-axis-id',
                            max: getMax(),
                            valueFormatter: valueFormatter,
                            tickMinStep: 86400000,
                            data: sessions.map((session) => (iso8601ToDate(session.exerciseStartDate).getTime()))
                        },
                    ]}
                    yAxis={[
                        {
                            min: 0,
                            id: 'y-axis-id',
                            tickNumber: sessions.length,
                            valueFormatter: (value) => { return getyAxisLabel(value); },

                        }
                    ]}
                    series={[
                        {
                            type: 'line',
                            curve: 'linear',
                            data: getSeries(),
                        },
                    ]}
                    margin={{ left: 80, right: 30, top: 30, bottom: 30 }}
                    sx={{
                        '& .MuiLineElement-root': {
                            strokeWidth: 3,
                        },
                        '& .MuiMarkElement-root': {
                            stroke: 'none',
                            fill: theme.palette.darkgreen.main,
                        }
                    }}
                    colors={[theme.palette.darkgreen.main]}
                    height={dimensions ? dimensions.height - menuHeight : 0}
                >
                    <LinePlot />
                    <ChartsXAxis label="" position="bottom" axisId="x-axis-id" />
                    <ChartsYAxis label="" position="left" axisId="y-axis-id" />
                    <MarkPlot />
                </ResponsiveChartContainer>
            </Box >

            <Stack style={{ paddingTop: '0px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                    <ClinicButton position="left" active={activeState === 0} onClick={(e) => { setActiveState(0); }}>Dauer</ClinicButton>
                    <ClinicButton position="center" active={activeState === 1} onClick={(e) => { setActiveState(1); }}>{getButtonLabel(1)}</ClinicButton>
                    <ClinicButton position="center" active={activeState === 2} onClick={(e) => { setActiveState(2); }}>{getButtonLabel(2)}</ClinicButton>
                    <ClinicButton position="right" active={activeState === 3} onClick={(e) => { setActiveState(3); }}>{getButtonLabel(3)}</ClinicButton>
                </Stack>
            </Stack>
        </Box >
    );
};

export default SessionsLineChart;
