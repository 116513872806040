import { SetStateAction } from "react";
import { useDeleteRetailerMutation, useGetRetailerQuery, useSwitchRetailerActiveMutation, useUndeleteRetailerMutation } from "../../../api/retailerApi";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { useAppSelector } from "../../../store";
import { selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import CloudMemberDetails from "../../CloudMember/DetailsPanel/CloudMemberDetails";
import { t } from 'i18next';
import { Trans } from "react-i18next";

type DetailsProps = {
    retailerId: string;
    setPage: (value: SetStateAction<Pages>) => void;
};

const RetailerDetails = ({ setPage, retailerId }: DetailsProps) => {
    const { data, isLoading } = useGetRetailerQuery(retailerId, { refetchOnMountOrArgChange: true });
    const [switchActive] = useSwitchRetailerActiveMutation();
    const [undelete] = useUndeleteRetailerMutation();
    const [deleteRetailer] = useDeleteRetailerMutation();

    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);

    if (isLoading) {
        return <div>loading...</div>;
    }
    const switchActiveFlag = () => {
        switchActive(retailerId);
    };

    const deleteEntry = () => {
        if (data?.deleted) {
            undelete(retailerId);
            return;
        }
        deleteRetailer(retailerId);
    };

    return (
        <CloudMemberDetails
            memberType={t('member-type-retailer')}
            deleteEntryMessage={<Trans i18nKey={'delete-retailer-message'} values={{ name: data?.name }} />}
            undeleteEntryMessage={<Trans i18nKey={'undelete-retailer-message'} values={{ name: data?.name }} />}
            data={data}
            switchActive={isSystemAdmin || isManufacturerAdmin ? switchActiveFlag : undefined}
            editEntry={isSystemAdmin || isRetailerAdmin ? () => setPage(Pages.EDIT) : undefined}
            deleteEntry={(isSystemAdmin) ? deleteEntry : undefined}
        />
    );
};

export default RetailerDetails;
